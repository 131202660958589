<template>

  <section id="form-pantalla">

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <font-awesome-icon 
            icon="tv" 
            class="color-secondary pe-1"
          />
          <strong>Añadir pantalla:</strong> Ingresa los datos informativos de la pantalla.
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div 
            class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0"
            :class="{'opacity-50': !add_view }"
            >
            <h5 class="font-main text-secondary">Validación pantalla</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Código</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      placeholder="Ingresa el nombre de tu canal"
                      maxlength="150"
                      v-model="code_key"
                      :disabled="add_view == false"
                    />
                  </div>
                </div> 
                <div class="row">
                  <div class="col-12 text-end">
                    <button
                      v-show="show_button_key == true"
                      class="btn btn-custom-color-blue border-round-50 mw-100"
                      :disabled="button_key == false"
                      @click="validateKey"
                    >
                      Validar
                    </button>
                  </div>
                </div>
                <div 
                  v-if="add_view"
                  class="row">
                  <div class="col-12">
                    <div 
                      v-show="show_button_key == false"
                      class="alert alert-success text-center">
                      Pantalla validada exitosamente
                    </div>
                  </div>
                  <Spinner v-if="show_spinner"/>
                </div>
              </div>
            </div>
          </div>
          <div 
            class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0"
            :class="{'opacity-50': !active_containers }"
            >
            <h5 class="font-main text-secondary">Nombre de la pantalla</h5>
            <div class="card flex-grow-1">
              <div class="card-body">

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Nombre</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      placeholder="Ingresa el nombre de la pantalla"
                      maxlength="150"
                      v-model="screen_name"
                      :disabled="active_containers == false"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Descripción</label>
                    <textarea 
                      class="form-control textarea-custom" 
                      placeholder="Ingresa una breve descripción de la pantalla"
                      maxlength="75"
                      rows="3"
                      v-model="screen_description"
                      :disabled="active_containers == false"
                    >
                    </textarea>
                    <div class="form-text text-end">
                      {{ screen_description.length }}/75
                    </div>
                  </div>
                </div>            

              </div>
            </div>
          </div>
          <div 
            class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0"
            :class="{'opacity-50': !active_containers }"
            >
            <h5 class="font-main text-secondary">Datos técnicos</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                  
                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary">Ubicación</label>
                    <input 
                      type="text" 
                      class="form-control input-custom" 
                      v-model="screen_location"
                      placeholder="Ingresa la ubicación exacta de la pantalla"
                      :disabled="active_containers == false" 
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Asignar canal</label>
                    <v-select
                      class="selvue-custom mb-3"
                      placeholder="Seleccionar un canal"
                      :clearable="false"
                      :options="GetCanalesOptions"
                      :reduce="canal => canal.id_canal"
                      label="nombre_canal"
                      v-model="screen_channel"
                      :disabled="active_containers == false"
                    >
                    <span slot="no-options"></span>
                    </v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Selecciona una categoría</label>
                    <v-select
                      class="selvue-custom mb-3"
                      placeholder="Seleccionar una zona"
                      :options="GetTimezones"
                      :reduce="zona => zona.zona_horaria"
                      label="zona_horaria"
                      v-model="screen_timezone"
                      :disabled="active_containers == false"
                      :clearable="false"
                      >
                    </v-select>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <router-link
                v-show="valid_code == false"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                :to="{ name: 'CanalCorporativo-pantallas' }"
                >
                Volver
              </router-link>
              <button
                v-show="valid_code == true"
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="rejectScreen()">
                Volver
              </button>
              <button
                v-show="add_view == true"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="addScreen()"
                :disabled="active_button == false"
              >
                Añadir
              </button>
              <button
                v-show="add_view == false"
                class="btn btn-custom-color-blue border-round-50 mw-100"
                @click="editScreen()"
                :disabled="active_button == false"
              >
                Editar
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {
  props: {
    id: {
      type: String,
    },
  },
  components: { Spinner },
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
      code_key: "",
      valid_code: false,
      show_spinner: false,
      show_spinner_large: false,
      button_key: false,
      show_button_key: true,
      active_containers: false,
      screen_name: "",
      screen_description: "",
      screen_location: "",
      screen_channel: "",
      screen_timezone: "",
      active_button: false,
      add_view: true,
    };
  },
  
  

  mounted() {
    this.getOptions();
    if (this.id) { 
      this.findScreen(this.id);
      this.add_view = false;
      this.active_containers = true;
      this.show_button_key = false;
    }
  },

  watch: {
    code_key() {
      if (this.code_key.length === 6) {
        this.button_key = true;
      } else {
        this.button_key = false;
      }
    },

    screenConfig() {
      if (this.screen_name && this.screen_description && this.screen_location && this.screen_channel && this.screen_timezone) {
        this.active_button = true;
      } else {
        this.active_button = false;
      }
    }
  },
  computed: {
    ...mapState("canalCorporativoModule", ["validationDisp", "GetCanalesOptions", "GetTimezones", "newSreen"]),
    ...mapGetters("canalCorporativoModule", ["getScreenById"]),

    screenConfig() {
      const { screen_name, screen_description, screen_location, screen_channel, screen_timezone } = this;
      return { screen_name, screen_description, screen_location, screen_channel, screen_timezone };      
    }

  },
  methods: {
    ...mapActions("canalCorporativoModule", [
      "ValidateScreenDispAction", "SaveScreenAction", "UpdateScreenAction", "GetOnePantallaAction", "GetListChannelsActions", "GetTimezonesActions",
      "RejectScreenSaving"
    ]),
    async rejectScreen() {
      let data_ = {
        id_usuario: this.id_usuario,
        codigo: this.code_key,
        estado: 'Rechazado'
      }
      let result = await this.RejectScreenSaving(data_).then(result => { return result });
      if (result != null) {
        if (result.id_dispositivo != null) {
            this.$toast.open({
              message: "Los cambio no han sido guardados",
              type: "success",
              duration: 6000,
              position: "top-right",
            });
            this.$router.push({ name: "CanalCorporativo-pantallas" });
        } else {
          this.$toast.open({
              message: "No se puede cancelar el proceso",
              type: "error",
              duration: 6000,
              position: "top-right",
          });
        }
      } else {
        this.$toast.open({
          message: "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
      
    },
    async validateKey() {
      this.show_spinner = true;
      let data_ = {
        id_usuario: this.id_usuario,
        codigo: this.code_key
      }
      let result = await this.ValidateScreenDispAction(data_).then(result => { return result });
      if (result == null) {
            this.valid_code = false;
            this.show_button_key = true;
            this.show_spinner = false;
            this.active_containers = false;
            this.open_modal_status = true;
            this.modal_status = true;
            this.modal_status_msg = `La cultura ha sido publicada con éxito`;
            this.$toast.open({
              message: "Problemas al cargar información, por favor contactar a soporte",
              type: "error",
              duration: 0,
              position: "top-right",
            });
      } else {
        if(result.id_dispositivo == null) {
            this.valid_code = false;
            this.show_button_key = true;
            this.show_spinner = false;
            this.active_containers = false;
            this.open_modal_status = true;
            this.modal_status = true;
            this.modal_status_msg = `La cultura ha sido publicada con éxito`;
            this.$toast.open({
              message: "El dispositivo no existe en nuestros registros",
              type: "error",
              duration: 0,
              position: "top-right",
            });
        } else if (result.id_dispositivo < 1) {
            this.valid_code = false;
            this.show_button_key = true;
            this.show_spinner = false;
            this.active_containers = false;
            this.open_modal_status = true;
            this.modal_status = true;
            this.modal_status_msg = `La cultura ha sido publicada con éxito`;
            this.$toast.open({
              message: "El dispositivo ya se encuentra en uso",
              type: "error",
              duration: 0,
              position: "top-right",
            });
        } else {
            this.screen_name = result.nombre_dispositivo
            this.valid_code = true;
            this.show_button_key = false;
            this.show_spinner = false;
            this.active_containers = true;
            this.open_modal_status = true;
            this.modal_status = true;
            this.modal_status_msg = `La cultura ha sido publicada con éxito`;
        }
      }
    },
    async getOptions(){
      let data_ = {
        id_empresa: this.id_empresa,
        id_estado: "-1"
      }
      let result = await this.GetListChannelsActions(data_).then(result => { return result });
      
      let zones = await this.GetTimezonesActions().then(result => { return result });
      if (result == null || zones == null) {
        this.$toast.open({
          message: "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
    },
    async findScreen(id) {
      let data_ = {
        id_empresa: this.id_empresa,
        id_pantalla: id
      }
      let result = await this.GetOnePantallaAction(data_).then(result => { return result });

      if (result == null) {
        this.$toast.open({
              message: "Problemas al cargar información, por favor contactar a soporte",
              type: "error",
              duration: 0,
              position: "top-right",
            });
      } else {
        if (result.id_pantalla != null) {
          this.screen_description = this.newSreen.descripcion_pantalla;
          this.screen_channel = this.newSreen.canal[0].id_canal;
          this.screen_timezone = this.newSreen.zona_horaria;
          this.code_key = this.newSreen.codigo;
          this.screen_name = this.newSreen.nombre_pantalla;
          this.screen_location = this.newSreen.ubicacion;
        } else {
          this.$toast.open({
              message: "La pantalla no existe",
              type: "error",
              duration: 0,
              position: "top-right",
            });
        }
        
      }
      
      // return result;
    },
    async addScreen() {
      this.show_spinner_large = true;
      let data_ = {
        id_usuario: this.id_usuario,
        id_empresa: this.id_empresa,
        codigo: this.code_key,
        nombre_pantalla: this.screen_name,
        descripcion_pantalla: this.screen_description,
        ubicacion: this.screen_location,
        id_canal: [this.screen_channel],
        zona_horaria: this.screen_timezone,
      }
      let result = await this.SaveScreenAction(data_).then(result => { return result });
      if (result != null) {
        this.show_spinner_large = false;
        if (result.id_pantalla != null) {
          this.$toast.open({
              message: "Tu pantalla ha sido agregada correctamente",
              type: "success",
              duration: 6000,
              position: "top-right",
            });
          this.$router.push({ name: "CanalCorporativo-pantallas" });
        } else {
            this.$toast.open({
              message: "Tu pantalla no se pudo registrar",
              type: "error",
              duration: 6000,
              position: "top-right",
            });
        }
      } else {
        this.show_spinner_large = false;
        this.$toast.open({
              message: "Problemas al cargar información, por favor contactar a soporte",
              type: "error",
              duration: 0,
              position: "top-right",
            });
      }
      
    },
    async editScreen() {
      this.show_spinner_large = true;
      let data_ = {
        id_pantalla: this.id,
        id_usuario: this.id_usuario,
        id_empresa: this.id_empresa,
        codigo: this.code_key,
        nombre_pantalla: this.screen_name,
        descripcion_pantalla: this.screen_description, 
        ubicacion: this.screen_location,
        id_canal: [this.screen_channel],
        zona_horaria: this.screen_timezone,
      }
      let result = await this.UpdateScreenAction(data_).then(result => { return result });
      if (result != null) {
        this.show_spinner_large = false;
        if (result.id_pantalla != null) {
          this.$toast.open({
            message: "Tu pantalla ha sido editada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "CanalCorporativo-pantallas" });
        } else {
            this.$toast.open({
              message: "Tu pantalla no se pudo editar",
              type: "error",
              duration: 6000,
              position: "top-right",
            });
        }
      } else {
        this.show_spinner_large = false;
        this.$toast.open({
          message: "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
    },
  },
};
</script>